import { useCallback, useEffect } from 'react';

import { WindowEventListeners } from '../globals/constants';

export interface UseOnWindowResizeHook {
  (callback: (this: Window, ev: UIEvent) => any): void;
}

export const useOnWindowResize: UseOnWindowResizeHook = (fn) => {
  const callback = useCallback(fn, []);
  useEffect(() => {
    window.addEventListener(WindowEventListeners.RESIZE, callback);
    return () => window.removeEventListener(WindowEventListeners.RESIZE, callback);
  }, []);
};
